/* tslint:disable */

const CommonService = {

    getValidateInputParams(paramValue: any, paramName?: string) {
        const onlyNumberRegex = /^\d+$/;
        const base64Regex = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/;
        const emailRegex = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;

        if(paramValue == 'true' || paramValue == 'false'){
            return paramValue;
        }
        else if(onlyNumberRegex.test(paramValue)) {
            return paramValue;
        }
        else if(base64Regex.test(paramValue)) {
            let value = atob(paramValue);
            if(paramName){
                if(paramName == 'leadId'){
                    return paramValue;
                }else if(paramName == 'email') {
                    return emailRegex.test(value) ? value : ""
                } else if(paramName == 'mobile') {
                    return onlyNumberRegex.test(value) ? value : ""
                }
            }
            // return value;
        }
        return paramValue;
    },
    wacky_round(number:number, places:number) {
        var multiplier = Math.pow(10, places + 2);
        var fixed = Math.round(number * multiplier);
        fixed = Math.floor(fixed / 10);
        return fixed / Math.pow(10, places + 1);
    },
    changeDisplayNumberFormat(numValue:number, places?:number,isLifeCoverFormat?:boolean):string {
        const lifeCoverCr = isLifeCoverFormat ? "Crore" : "Cr";
        let num = parseInt(numValue.toString(), 10);
        // let num = tempNum.toString();
        let numLength = num.toString().length, displayNum, suffix = '';
        if (numLength == 4 || numLength == 5) {
            displayNum = num / 1000;
            displayNum = Math.round(this.wacky_round(displayNum, 1) * 1000);
        } else if (numLength == 6 || numLength == 7) {
            displayNum = num / 100000;
            displayNum = Math.round(this.wacky_round(displayNum, 1) * 100000);
        } else if (numLength == 8 || numLength == 9 || numLength == 10) {
            displayNum = num / 10000000;
            displayNum = Math.round(this.wacky_round(displayNum, 1) * 10000000);
        } else {
            displayNum = num;
        }
        displayNum = parseInt(displayNum.toString(), 10);
        numLength = displayNum.toString().length,
            suffix = '';
        if (numLength == 4 || numLength == 5) {
            displayNum = displayNum / 1000;
            suffix = (displayNum > 1) ? "K" : "K";
        } else if (numLength == 6) {
            displayNum = displayNum / 100000;
            suffix = (displayNum > 1) ? "Lac" : "Lac";
        } else if (numLength == 7) {
            displayNum = displayNum / 100000;
            suffix = (displayNum > 1) ? "Lac" : "Lac";
        } else if (numLength == 8 || numLength == 9 || numLength == 10) {
            displayNum = displayNum / 10000000;
            suffix = (displayNum > 1) ? lifeCoverCr : lifeCoverCr;
        } else if (numLength > 10) {
            displayNum = this.changeDisplayNumberFormat(displayNum / 10000000);
            suffix = lifeCoverCr;
        }
        else {
            displayNum = num;
        }
        displayNum = displayNum.toString();
        try {
            if (places && displayNum && displayNum.indexOf('.') > 0) {
                displayNum = [displayNum.split('.')[0], displayNum.split('.')[1].substring(0, places)].join('.');
            }
        } catch (e) { }

        return displayNum + " " + suffix;
    },
    addCommas(nStr: string) {
        if (!nStr)
            return '';

        nStr = nStr.toString();
        var lastThree = nStr.substring(nStr.length - 3),
            otherNumbers = nStr.substring(0, nStr.length - 3),
            res;

        if (otherNumbers !== '')
            lastThree = ',' + lastThree;

        res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        return res;
    },
    removeCommas(x: any) {
        let returnedAmount = 0;
        if (x !== undefined && x !== null) {
            x = x.toString();
            if (x !== undefined && x !== null && x.match(/\d+/g) != null) {
                returnedAmount = isNaN(x) ? x.replace(/,/g, '') : x;
            }
        }
        return Number(returnedAmount);
    } 
}

export default CommonService;