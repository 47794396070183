import environment from "@/environment";
import globalService from '@/services/globalService';
import axios from 'axios';
const event_logger_url = environment.event_logger_url;

const eventLogService = {
  logActivity(data: any): void {
    try {
      Object.keys(data).forEach((key:any) => {
        if (data[key] == null || key=="mobile" || key=="mobileNumber" || key=="mobileNo") {
          delete data[key];
        }
      });
      postrequest(`${event_logger_url}`, data);
    } catch (err) { console.log(err); }
  },

  setactivityData(event: string, page: string, logdata = {}): void {
    try {
      const data = Object.assign({ event, pageName: page }, this.commonUrlData(), logdata);
      this.logActivity(data);
    } catch (error) {}
  },

  commonUrlData(): any {
    return {
      leadSource: globalService.isMobileDevice() ? "PBMOBILE" : "PB",
    };
  }
};


function postrequest(url: string, params: any = {}) {
  try {
    return axios.post(url, params).then((response: any) => {
      return response ? response.data : {};
    }, (error: any) => {
      console.log(error);
    });
  } catch (err) {
    console.log(err);
  }
}

export default eventLogService;
