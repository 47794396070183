import Vue from 'vue';
import {createRouter,createWebHistory} from 'vue-router';
// Vue.use(Router);

const routes = [
  // {
  //   path: '/',
  //   name: 'prequotes',
  //   component: () => import(/* webpackChunkName: "prequotes" */ './views/prequotes.vue'),
  //   // component: prequotes,
  // },
  // {
  //   path: '/insurer',
  //   name: 'insprequotes',
  //   component: () => import(/* webpackChunkName: "insprequotes" */ './views/insprequotes.vue'),
  //   // component: prequotes,
  // },
  {
    path: '/sjb',
    name: 'sjbprequotes',
    component: () => import(/* webpackChunkName: "sjbprequotes" */ './views/sjbprequotes.vue'),
    // component: prequotes,
  },
  {
    path: '/',
    name: 'prequotes',
    component: () => import(/* webpackChunkName: "prequotes" */ './views/prequotes.vue'),
    // component: prequotes,
  },
  {
    path: '/bmi',
    name: 'bmicalculator',
    component: () => import(/* webpackChunkName: "bmicalculator" */ './views/bmicalculator.vue'),
    // component: prequotes,
  },
  {
    path: '/posp',
    name: 'pospprequotes',
    component: () => import(/* webpackChunkName: "pospprequotes" */ './views/pospprequotes.vue'),
    // component: prequotes,
  },
  
  {
    path: '/eligibility',
    name: 'eligibilitycalculator',
    component: () => import(/* webpackChunkName: "eligibilitycalculator" */ './views/eligibilitycalculator.vue'),
    // component: prequotes,
  },
  {
    path: '/spouse',
    name: 'spousePrequote',
    component: () => import(/* webpackChunkName: "spousePrequote" */ './views/spousePrequote.vue'),
  },
  {
    path: '/specialday',
    name: 'specialday',
    component: () => import(/* webpackChunkName: "womenPrequote" */ './views/womenPrequote.vue'),
  },
  {
    path: '/lic-prequote',
    name: 'licprequote',
    component: () => import(/* webpackChunkName: "womenPrequote" */ './views/LicPrequote.vue'),
  },
  
  {
    path: '/health',
    name: 'health',
    component: () => import(/* webpackChunkName: "health" */ './views/health.vue'),
  },
  {
    path: '/engineers',
    name: 'engineers',
    component: () => import(/* webpackChunkName: "engineerPrequote" */ './views/prequotes.vue'),
  },
  {
    path: '/terminvite',
    name: 'terminvite',
    component: () => import(/* webpackChunkName: "nriInvite" */ './views/nriInvite.vue'),
  },
  {
    path: '/bthinvite',
    name: 'birthdayterminvite',
    component: () => import(/* webpackChunkName: "birthdaynriInvite" */ './views/birthdaynriInvite.vue'),
  },
  {
    path: '/maxinvite',
    name: 'maxTermInvite',
    component: () => import(/* webpackChunkName: "maxTermInvite" */ './views/maxTermInvite.vue'),
  },
  {
    path: '/:catchAll(.*)', // Define catch-all parameter with a custom regex
    redirect: '/',
  },
  {
    path: '/pb-employee',
    name: 'pbprequotes',
    component: () => import(/* webpackChunkName: "prequotes" */ './views/pbprequotes.vue'),
    // component: prequotes,
  },
];

const router = createRouter({
  history:createWebHistory(),
  routes
})
export default router;

